import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class ChapterPageFreeTrialType {
  @Expose() id: number

  @Expose() imgPath: string

  @Expose() content: string

  @Expose() ordered: number
}
