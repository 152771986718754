import { Expose, Transform } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'

export class EbookProfileType {
  @Expose() id: number

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() coverImgPath: string

  @Expose()
  @Transform(({ value }) => value || 0)
  totalEbookSeries: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalEbookSeriesPublish: number

  @Expose()
  @Transform(({ value }) => value || 0)
  avgEbookRating: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalEbookReview: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalEbookWishlist: number
}
