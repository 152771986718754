/* eslint-disable max-classes-per-file */
import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookRatingType } from '@models/book/BookRatingType'
import { BookTagsType } from '@models/book/BookTagsType'
import { BookEnum } from '@interfaces/BookEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { EbookReviewStatusEnum } from '@interfaces/EbookReviewStatusEnum'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'
import { EbookStepEnum } from '@interfaces/EbookStepEnum'
import { PromotionEbookType } from './PromotionEbookType'

class WriterType {
  affiliation: AffiliationEnum

  status: WriterStatusEnum
}

class UserType {
  id: number

  @Type(() => WriterType)
  writer: WriterType

  displayName: string

  avatarImgPath: string

  profilePageSlug: string

  email: string

  isAdmin: boolean
}

class EbookChaptersType {
  id: number

  isTrial: boolean
}

export class PenNameType {
  id: number

  firstPenName: string

  facebookLink: string

  twitterLink: string

  @Type(() => UserType)
  user: UserType
}

@Exclude()
export class EbookDetailType {
  @Expose() id: number

  @Expose()
  @Transform(({ obj }) => obj.book?.id)
  bookId: number

  @Expose() writer: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Transform(({ value }) =>
    value === WritingEnum.ORIGINAL
      ? 'ออริจินอล'
      : value === WritingEnum.FAN_FICTION
      ? 'แฟนฟิกชัน'
      : ''
  )
  writingType: string

  @Expose() isTranslated: boolean

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() viewCount: number

  @Expose() wishlistCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  reviewCount: number

  @Expose() lastCommentDate: string

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose() intro: string

  @Expose() naiinCoverImgPath: string

  @Expose() naiinTitle: string

  @Expose() naiinCover: string

  @Expose() naiinLink: string

  @Expose() updatedAt: string

  @Expose() publishedAt: string

  @Expose() createdAt: string

  @Expose() profilePageSlug: string

  @Expose() isPaid: boolean

  @Expose() bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value ?? false)
  isWishlist: boolean

  @Expose() dynamicLink: string

  @Expose() facebookShareImageUrl?: string

  @Expose() twitterShareImageUrl?: string

  @Expose() requireAgeVerify: boolean

  @Expose() isbnNo: string

  @Expose()
  @Type(() => PromotionEbookType)
  promotion?: PromotionEbookType

  @Expose()
  @Transform(({ value }) => value ?? 0)
  avgRating: number

  @Expose() price: number

  @Expose() ebookReviewStatus: EbookReviewStatusEnum

  @Expose() ebookChapters: EbookChaptersType[]

  @Expose() totalPageCount: number

  @Expose() totalWordCount: number

  @Expose() chapterCount: number

  @Expose()
  @Transform(({ obj }) => {
    return obj.isVisible === false
      ? EbookStatusEnum.UNPUBLISHED
      : obj.ebookStatus
  })
  ebookStatus: EbookStatusEnum

  @Expose() ebookStep: EbookStepEnum
}
