import { Expose } from 'class-transformer'

export class BookCategoryType {
  @Expose() id: number

  @Expose() name: string

  @Expose() ordered: number

  @Expose() imgPath: string

  @Expose() description: string
}
