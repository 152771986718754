import { gql } from 'graphql-request'

export const GET_MY_EBOOK = gql`
  query GetMyEbook($ebookId: Float!) {
    getMyEbook(ebookId: $ebookId) {
      id
      writer
      penName {
        id
        user {
          id
        }
      }
      rating {
        id
      }
      tags {
        id
        name
      }
      category {
        id
      }
      coverImgPath
      title
      intro
      cover
      orientation
      isTranslated
      writingType
      bookType
      chapterCount
      price
      promotions {
        discountPrice
        discountPercent
        discountCoin
        endPublishedAt
        id
        isPublish
        price
        startPublishedAt
      }
      ebookReviewStatus
      isbnNo
      hasTrial
      isCheckAge
      publishedAt
      reviews {
        id
      }
      ebookStatus
      ebookChapterSelectType
      isVisible
      ebookStep
    }
  }
`
