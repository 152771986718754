import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { Expose, Transform } from 'class-transformer'

export class BookChapterForCreateType {
  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() isSelect: boolean

  @Expose() id: number

  @Expose() status: ChapterStatusEnum

  @Expose()
  @Transform(() => false)
  isCreate: boolean

  @Expose()
  @Transform(() => false)
  isDeleted: boolean

  number: number
}
