import { Expose, Transform } from 'class-transformer'

import type { ImageType } from '@interfaces/ImageType'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'
import { TagType } from '../story/TagType'

export class EbookDetailFormType {
  @Expose() id: number

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Transform(({ value }) => value?.toUpperCase())
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value?.toUpperCase())
  orientation?: OrientationEnum

  @Expose()
  @Transform(({ value }) => value?.toUpperCase(), { toClassOnly: true })
  writingType: WritingEnum

  @Expose()
  @Transform(({ value }) => value ?? '', { toClassOnly: true })
  writer: string

  @Expose()
  @Transform(({ obj }) => obj.category?.id, { toClassOnly: true })
  categoryId?: number

  @Expose()
  @Transform(({ obj }) => obj.rating?.id, { toClassOnly: true })
  ratingId?: number

  @Expose()
  @Transform(({ obj }) => obj.penName?.id, { toClassOnly: true })
  penNameId?: number

  @Expose()
  @Transform(({ obj }) => obj.penName?.user?.id, { toClassOnly: true })
  penNameUserId?: number

  @Expose()
  @Transform(({ value }) => value.map((tag: TagType) => tag.name))
  tags: string[]

  @Expose()
  @Transform(
    ({ value }) => {
      return value.url
    },
    { toPlainOnly: true }
  )
  @Transform(({ obj }) => ({ url: obj?.coverImgPath || '' }), {
    toClassOnly: true,
  })
  coverImgPath: ImageType

  @Expose() isTranslated: boolean

  @Expose() intro: string

  @Expose()
  @Transform(({ value }) => value || '')
  isbnNo: string

  @Expose({ name: 'chapterCount' })
  totalChapter: number

  @Expose() ebookStatus?: EbookStatusEnum

  @Expose() isVisible: boolean
}
