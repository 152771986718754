import { gql } from 'graphql-request'

export const GET_BOOK_DETAIL = gql`
  query GetBookDetail($bookId: Float!) {
    getBookDetail(bookId: $bookId) {
      averageReview
      book {
        id
        title
        coverImgPath
        bookType
        writingType
        rating {
          id
          name
        }
        category {
          id
          name
        }
        avgEbookRating
        cover
        penName {
          id
          firstPenName
          user {
            id
            displayName
            avatarImgPath
            username
          }
        }
        isTranslated
        requireAgeVerify
        writer
        facebookSeriesShareImageUrl
        twitterSeriesShareImageUrl
      }
      writer {
        affiliation
        user {
          id
          username
          displayName
          avatarImgPath
          profilePageSlug
          email
          isAdmin
        }
      }
    }
  }
`
