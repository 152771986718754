import { Expose, Transform, Type } from 'class-transformer'

import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'
import { EbookStepEnum } from '@interfaces/EbookStepEnum'
import { BookEnum } from '@interfaces/BookEnum'

export class PromotionEbookType {
  @Expose() id: number

  @Expose() isPublish: boolean

  @Expose() price: number

  @Expose() discountPrice: number

  @Expose() discountPercent: number

  @Expose() discountCoin: number

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string
}

export class EbookSeriesType {
  @Expose() id: number

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() coverImgPath: string

  @Expose() coverResizeImgPath: string

  @Expose() chapterCount: number

  @Expose() purchaseCount: number

  @Expose() price: number

  @Expose() publishedAt: string

  @Expose()
  @Transform(({ obj }) => {
    const datetime = new Date().valueOf()
    const publishedAt = new Date(obj.publishedAt).valueOf()

    return (
      obj.ebookStatus === EbookStatusEnum.PUBLISHED && datetime < publishedAt
    )
  })
  showPublishedAt: boolean

  @Expose()
  @Transform(({ obj }) => {
    return obj.isVisible === false
      ? EbookStatusEnum.UNPUBLISHED
      : obj.ebookStatus
  })
  ebookStatus: EbookStatusEnum

  @Expose() ebookStep: EbookStepEnum

  @Expose()
  @Transform(({ value }) => value || 0)
  avgRating: number

  @Expose()
  @Transform(({ value }) => value || 0)
  reviewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  wishlistCount: number

  @Expose()
  @Type(() => PromotionEbookType)
  promotion?: PromotionEbookType

  @Expose() isVisible: boolean
}
