import { Expose, Type } from 'class-transformer'
import { SearchCategoryEbookType } from './SearchCategoryEbookType'

export class SearchCategoryEbookResponse {
  @Expose()
  @Type(() => SearchCategoryEbookType)
  data: SearchCategoryEbookType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
