import { gql } from 'graphql-request'

export const GET_CATEGORY_EBOOK_LIST = gql`
  query EbookAdvanceSearchPaginate(
    $limitPerPage: Int
    $page: Int
    $bookType: BookType
    $searchType: EbookSearchTypeEnum!
    $orderBy: EbookOrderBy
    $categoryId: Int
    $hitScope: HitScopeEnum
  ) {
    ebookAdvanceSearchPaginate(
      limitPerPage: $limitPerPage
      page: $page
      bookType: $bookType
      searchType: $searchType
      orderBy: $orderBy
      categoryId: $categoryId
      hitScope: $hitScope
    ) {
      data {
        id
        coverImgPath
        cover
        title
        wishlistCount
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            profilePageSlug
          }
        }
        tags {
          id
          name
        }
        bookType
        publishedAt
        avgRating
        price
        isWishlist
        promotion {
          id
          discountPrice
          startPublishedAt
          endPublishedAt
          price
          isPublish
          discountCoin
          discountPercent
        }
        reviewCount
      }
      page
      total
    }
  }
`
