import { gql } from 'graphql-request'

export const UPDATE_EBOOK = gql`
  mutation UpdateEbook($updateEbookInput: UpdateEbookInput!) {
    updateEbook(UpdateEbookInput: $updateEbookInput) {
      id
      tags {
        name
      }
    }
  }
`
