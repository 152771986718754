import { gql } from 'graphql-request'

export const GET_OTHER_EBOOKS = gql`
  query GetOtherEbookInSeries($bookId: Int!, $ebookId: Int!) {
    getOtherEbookInSeries(bookId: $bookId, ebookId: $ebookId) {
      id
      title
      cover
      tags {
        id
        name
      }
      category {
        id
        name
      }
      penName {
        firstPenName
        user {
          id
          profilePageSlug
        }
      }
      coverImgPath
      coverResizeImgPath
      wishlistCount
      bookType
      publishedAt
      writingType
      rating {
        id
        name
      }
      promotion {
        id
        discountPrice
        startPublishedAt
        endPublishedAt
        price
        isPublish
        discountCoin
        discountPercent
      }
      price
      avgRating
      reviewCount
      ebookReviewStatus
      isWishlist
    }
  }
`
