import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { BookRatingType } from '@models/book/BookRatingType'

class UserType {
  @Expose() id: number

  @Expose() username: string

  @Expose() displayName: string

  @Expose() avatarImgPath: string

  @Expose() profilePageSlug: string

  @Expose() email: string

  @Expose() isAdmin: boolean
}

export class PenNameType {
  id: number

  firstPenName: string

  user: UserType
}

export class CategoryType {
  @Expose() id: number

  @Expose() name: string
}

export class BookType {
  @Expose() id: number

  @Expose() title: string

  @Expose() coverImgPath?: string

  @Expose() bookType: BookEnum

  @Expose()
  writingType: WritingEnum

  @Expose()
  @Transform(({ obj }) =>
    obj.writingType === WritingEnum.ORIGINAL
      ? 'ออริจินอล'
      : obj.writingType === WritingEnum.FAN_FICTION
      ? 'แฟนฟิกชัน'
      : ''
  )
  writingTypeValue: string

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose()
  @Type(() => CategoryType)
  category: CategoryType

  @Expose() avgEbookRating: number

  @Expose() cover: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() isTranslated: boolean

  @Expose() requireAgeVerify: boolean

  @Expose() writer: string

  @Expose()
  @Transform(({ obj }) => obj.writingType === WritingEnum.FAN_FICTION)
  isFanfic: boolean

  @Expose() facebookSeriesShareImageUrl: string

  @Expose() twitterSeriesShareImageUrl: string
}

export class WriterType {
  @Expose() affiliation: AffiliationEnum

  @Expose()
  @Type(() => UserType)
  user: UserType
}

@Exclude()
export class BookDetailTypeResponse {
  @Expose()
  @Type(() => BookType)
  book: BookType

  @Expose()
  @Type(() => WriterType)
  writer: WriterType

  @Expose()
  @Transform(({ value }) => value ?? 0)
  averageReview: number
}
