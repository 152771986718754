import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { PenNameType } from '@models/penName/PenNameType'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'

@Exclude()
class RatingType {
  @Expose()
  id: number
}

@Exclude()
export class EbookFreeTrialType {
  @Expose() id: number

  @Expose() title: string

  @Expose() screenCapturable: boolean

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  orientation: OrientationEnum

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  bookType: BookEnum

  @Expose()
  ebookStatus: EbookStatusEnum

  @Expose() coverImgPath: string

  @Expose() requireAgeVerify: boolean

  @Expose()
  @Type(() => RatingType)
  rating: RatingType

  @Expose() publishedAt: string
}
