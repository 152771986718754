import { Exclude, Expose, Type } from 'class-transformer'
import { UserType } from '@models/user/UserType'

@Exclude()
export class PenNameType {
  @Expose() id: number

  @Expose() firstPenName: string

  @Expose() secondPenName: string

  @Expose() facebookLink: string

  @Expose() twitterLink: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}
