/* eslint-disable max-classes-per-file */
import { BookEnum } from '@interfaces/BookEnum'
import { TagType } from '@models/story/TagType'
import { Expose, Transform, Type } from 'class-transformer'
import { PromotionEbookType } from './PromotionEbookType'

class UserType {
  @Expose() id: number

  @Expose()
  profilePageSlug: string
}

class PenNameType {
  @Expose() firstPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}

class CategoryType {
  @Expose() id: number

  @Expose() name: string
}

export class EbookPromotionOnSaleType {
  @Expose()
  id: number

  @Expose()
  title: string

  @Expose()
  cover: string

  @Expose()
  writer: string

  @Expose() coverImgPath: string

  @Expose() coverResizeImgPath: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose()
  bookType: BookEnum

  @Expose()
  category: CategoryType

  @Expose()
  @Type(() => TagType)
  tags: TagType[]

  @Expose()
  @Transform(({ value }) => value || 0)
  avgRating: number

  @Expose()
  @Transform(({ value }) => value || 0)
  reviewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  wishlistCount: number

  @Expose()
  price: number

  @Expose()
  @Transform(({ value }) => value ?? false)
  isWishlist: boolean

  @Expose()
  @Type(() => PromotionEbookType)
  promotion: PromotionEbookType
}
