import { Expose, Type } from 'class-transformer'
import { MyOtherEbooksType } from './MyOtherEbooksType'

export class SearchEbookResponse {
  @Expose()
  @Type(() => MyOtherEbooksType)
  data: MyOtherEbooksType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
