import { gql } from 'graphql-request'

export const CREATE_PROMOTION_EBOOK = gql`
  mutation CreatePromotionEbook(
    $createPromotionEbookInput: CreatePromotionEbookInput!
  ) {
    createPromotionEbook(
      createPromotionEbookInput: $createPromotionEbookInput
    ) {
      message
    }
  }
`
