import { gql } from 'graphql-request'

export const GET_EBOOK_READER = gql`
  query GetEbookReader($bookId: Int!, $limitPerPage: Int, $page: Int) {
    getEbookReader(bookId: $bookId, limitPerPage: $limitPerPage, page: $page) {
      total
      page
      data {
        id
        title
        cover
        writer
        coverImgPath
        coverResizeImgPath
        penName {
          firstPenName
          user {
            profilePageSlug
          }
        }
        bookType
        category {
          id
          name
        }
        tags {
          id
          name
        }
        avgRating
        reviewCount
        wishlistCount
        price
        isWishlist
        promotion {
          id
          price
          discountPrice
          discountCoin
          discountPercent
          startPublishedAt
          endPublishedAt
          isPublish
        }
      }
    }
  }
`
