import { gql } from 'graphql-request'

export const GET_STORY = gql`
  query Book($id: Int!) {
    book(id: $id) {
      id
      writer
      penName {
        id
        user {
          id
        }
      }
      rating {
        id
      }
      tags {
        id
        name
      }
      category {
        id
      }
      chapterType
      coverImgPath
      title
      intro
      cover
      orientation
      isTranslated
      writingType
      bookType
      chapterCount
    }
  }
`
