import { gql } from 'graphql-request'

export const GET_EBOOK_NEWEST = gql`
  query EbookNewest(
    $limitPerPage: Int
    $page: Int
    $searchType: EbookSearchTypeEnum!
    $orderBy: EbookOrderBy
  ) {
    ebookAdvanceSearchPaginate(
      limitPerPage: $limitPerPage
      page: $page
      searchType: $searchType
      orderBy: $orderBy
    ) {
      data {
        id
        coverImgPath
        cover
        title
        wishlistCount
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            profilePageSlug
          }
        }
        tags {
          id
          name
        }
        bookType
        publishedAt
        avgRating
        price
        isWishlist
        promotion {
          id
          discountPrice
          startPublishedAt
          endPublishedAt
          price
          isPublish
          discountCoin
          discountPercent
        }
        reviewCount
      }
      page
      total
    }
  }
`
