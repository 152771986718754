import { gql } from 'graphql-request'

export const CREATE_CHAPTER_FORM_BOOK = gql`
  mutation CreateChapterFromBook(
    $createEbookChapterFromBookInput: CreateEbookChapterFromBookInput!
  ) {
    createChapterFromBook(
      CreateEbookChapterFromBookInput: $createEbookChapterFromBookInput
    ) {
      id
    }
  }
`
