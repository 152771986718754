import { gql } from 'graphql-request'

export const GET_BOOK_CHAPTER_FOT_CREATE = gql`
  query GetBookChapterForCreate(
    $getBookChapterForCreateArgs: GetBookChapterForCreateArgs!
  ) {
    getBookChapterForCreate(
      GetBookChapterForCreateArgs: $getBookChapterForCreateArgs
    ) {
      chapterName
      chapterTitle
      id
      status
      isSelect
    }
  }
`
