import { gql } from 'graphql-request'

export const UPDATE_EBOOK_SORTING = gql`
  mutation UpdateEbookChapterSorting(
    $updateNewEbookChapterSorting: UpdateNewEbookChapterSorting!
  ) {
    updateEbookChapterSorting(
      UpdateNewEbookChapterSorting: $updateNewEbookChapterSorting
    ) {
      bookChapterId
    }
  }
`
