import { Expose, Type } from 'class-transformer'

export class EbookReviewType {
  @Expose()
  id: number

  @Expose()
  content: string

  @Expose()
  star: number

  @Expose()
  @Type(() => UserType)
  user: UserType

  @Expose()
  updatedAt: string

  @Expose() visible: boolean
}

class UserType {
  @Expose() id: number

  @Expose()
  isAdmin: boolean

  @Expose()
  email: string

  @Expose()
  avatarImgPath: string

  @Expose()
  displayName: string

  @Expose()
  profilePageSlug: string
}
