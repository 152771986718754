import { Expose, Transform, Type } from 'class-transformer'

import { Pagination } from '@models/pagination/pagination'
import { EbookProfileType } from './EbookProfileType'

export class PaginatedEbookProfile extends Pagination<EbookProfileType> {
  @Expose()
  @Type(() => EbookProfileType)
  @Transform(({ value }) => value || [])
  data: EbookProfileType[]

  @Expose() totalEbook: number
}
