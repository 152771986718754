import { DateTime } from 'luxon'
import { plainToInstance } from 'class-transformer'

import { gqlApiInstance } from '@client/init'
import { SEARCH_EBOOK_PROMOTION_ON_SALE } from '@client/collections/Ebook/schemas/searchEbookPromotionOnSale'
import { SEARCH_WRITING_EBOOK_PROFILE } from '@client/collections/Ebook/schemas/searchWritingEbookProfile'
import { PaginatedEbookProfile } from '@models/ebook/PaginatedEbookProfile'
import { GET_STORY } from '@client/collections/Ebook/schema/getStory'
import { EbookDetailFormType } from '@models/ebook/EbookDetailFormType'
import { CREATE_EBOOK } from '@client/collections/Ebook/schema/createEbook'
import { SettingEbookFormType } from '@models/ebook/SettingEbookFormType'
import { PublishedTypeEnum } from '@interfaces/PublishedTypeEnum'
import { EbookStatusEnum } from '@interfaces/EbookStatusEnum'
import { ResizeTypeEnum } from '@interfaces/ResizeTypeEnum'
import { UPLOAD_FILE } from '@client/collections/File/schemas/uploadFile'
import { SortEnum } from '@interfaces/SortEnum'
import { GET_BOOK_CHAPTER_FOT_CREATE } from '@client/collections/Ebook/schema/getBookChapterForCreate'
import { BookChapterForCreateType } from '@models/ebook/BookChapterForCreateType'
import { CREATE_CHAPTER_FORM_BOOK } from '@client/collections/Ebook/schema/createChapterFromBook'
import { DELETE_EBOOK_CHAPTER } from '@client/collections/Ebook/schema/deleteEbookChapter'
import { GET_EBOOK_CHAPTERS } from '@client/collections/Ebook/schema/getEbookChapters'
import { EbookChapterType } from '@models/ebook/EbookChapterType'
import { UPDATE_EBOOK_SORTING } from '@client/collections/Ebook/schema/updateEbookSorting'
import { GET_EBOOK_CHAPTER } from '@client/collections/Ebook/schema/getEbookChapter'
import { EbookChapterFormType } from '@models/ebook/EbookChapterFormType'
import { CREATE_EBOOK_CHAPTER } from '@client/collections/Ebook/schema/createEbookChapter'
import { UPDATE_EBOOK_CHAPTER } from '@client/collections/Ebook/schema/updateEbookChapter'
import { GET_MY_EBOOK } from '@client/collections/Ebook/schema/getMyEbook'
import { UPDATE_EBOOK } from '@client/collections/Ebook/schema/updateEbook'
import { UPDATE_EBOOK_SETTING } from '@client/collections/Ebook/schema/updateEbookSetting'
import { CREATE_PROMOTION_EBOOK } from '@client/collections/Ebook/schema/createPromotionEbook'
import { UPDATE_PROMOTION_EBOOK } from '@client/collections/Ebook/schema/updatePromotionEbook'
import { EbookTestBookEnum } from '@interfaces/EbookTestBookEnum'
import { GET_BOOK_DETAIL } from '@client/collections/Ebook/schemas/getBookDetail'
import { GET_EBOOK_SERIES } from '@client/collections/Ebook/schemas/getEbookSeries'
import { BookDetailTypeResponse } from '@models/ebook/BookDetailType'
import { EbookSeriesType } from '@models/ebook/EbookSeriesType'
import { StatusEnum } from '@interfaces/StatusEnum'
import { EbookReviewResponse } from '@models/ebook/EbookReviewResponse'
import { EbookDetailType } from '@models/ebook/EbookDetailType'
import { GET_EBOOK_READER_DETAIL } from '@client/collections/Ebook/schemas/getEbookReaderDetail'
import { GET_EBOOK_REVIEW_PAGINATE } from '@client/collections/Ebook/schemas/getEbookReviewPaginate'
import { CREATE_EBOOK_REVIEW } from '@client/collections/Ebook/schemas/createEbookReview'
import { MyOtherEbooksType } from '@models/ebook/MyOtherEbooksType'
import { GET_OTHER_EBOOKS } from '@client/collections/Ebook/schemas/getOtherEbookInSeries'
import { REMOVE_EBOOK } from '@client/collections/Ebook/schemas/removeEbook'
import { UpdatePromotionEbookFormType } from '@models/ebook/UpdatePromotionEbookFormType'
import { SEARCH_EBOOK } from '@client/collections/Ebook/schemas/searchEbook'
import { SearchEbookParam } from '@models/ebook/searchEbookParam'
import { SearchEbookResponse } from '@models/ebook/SearchEbookResponse'
import { UPDATE_EBOOK_STEP } from '@client/collections/Ebook/schemas/updateEbookStep'
import { EbookStepEnum } from '@interfaces/EbookStepEnum'
import { SearchCategoryEbookResponse } from '@models/ebook/SearchCategoryEbookResponse'
import { AllCategoryEbookSearchType } from '@models/ebook/AllCategoryEbookSearchType'
import { GET_CATEGORY_EBOOK_LIST } from '@client/collections/Ebook/schemas/getAllCategoryEbookList'
import { BookEnum } from '@interfaces/BookEnum'
import { GET_EBOOK_WRITER_DETAIL } from '@client/collections/Ebook/schemas/getEbookWriterDetail'
import { ReaderSeriesRespone } from '@models/ebook/ReaderSeriesRespone'
import { GET_EBOOK_READER } from '@client/collections/Ebook/schemas/getEbookReader'
import { UPDATE_EBOOK_SETTING_IDS } from '@client/collections/Ebook/schemas/updateEbookSettingIds'
import { EbookPromotionOnSaleResponse } from '@models/ebook/EbookPromotionOnSaleResponse'
import { EbookFreeTrialOptionsResponse } from '@models/ebook/EbookFreeTrialOptionsResponse'
import { GET_FREE_TRIAL_EBOOK_LIST } from '@client/collections/Ebook/schemas/getFreeTrialEbookList'
import { EbookFreeTrialChapterType } from '@models/ebook/EbookFreeTrialChapterType'
import { GET_FREE_TRIAL_EBOOK_CHAPTER_READER } from '@client/collections/Ebook/schemas/getFreeTrialEbookChapterReader'
import { BuyEbookFormType } from '@models/ebook/BuyEbookFormType'
import { UserCoin } from '@models/user/UserCoin'
import { clearComma } from '@lib/utils'
import { BUY_EBOOK } from '@client/collections/Ebook/schemas/buyEbook'
import { GET_EBOOK_NEWEST } from '@client/collections/Ebook/schemas/getEbookNewest'
import { EbookSearchTypeEnum } from '@interfaces/EbookSearchTypeEnum'
import { GET_RECOMMEND_EBOOK } from '@client/collections/Ebook/schemas/getRecommendEbook'
import { RecommendEbookType } from '@models/ebook/RecommendEbookType'
import { EbookRecommendEnum } from '@interfaces/EbookRecommendEnum'
import { GET_TOTAL_PAGE_AND_WORD_COUNT } from '@client/collections/Ebook/schema/getTotalPageAndWordCount'
import { TotalPageAndWordCountType } from '@models/ebook/TotalPageAndWordCountType'
import { UPDATE_EBOOK_REVIEW_VISIBLE } from '@client/collections/Ebook/schemas/updateEbookReviewVisible'
import { EbookManagementType } from '@models/ebook/EbookManagementType'

export function useEbookAction() {
  async function getEbookReaderDetail(
    ebookId: number
  ): Promise<EbookDetailType> {
    const resp = await gqlApiInstance.request(GET_EBOOK_READER_DETAIL, {
      ebookId,
    })

    return plainToInstance(EbookDetailType, resp.getEbookReaderDetail)
  }

  async function getEbookReviewPaginate({
    ebookId,
    page,
    limitPerPage,
  }: {
    ebookId: number
    page: number
    limitPerPage: number
  }): Promise<EbookReviewResponse> {
    const resp = await gqlApiInstance.request(GET_EBOOK_REVIEW_PAGINATE, {
      ebookId,
      page,
      limitPerPage,
    })
    return plainToInstance(EbookReviewResponse, resp.getEbookReviewPaginate)
  }

  async function createEbookReview({
    star,
    ebookId,
    content,
  }: {
    star: number
    ebookId: number
    content: string
  }): Promise<StatusEnum> {
    const resp = await gqlApiInstance.request(CREATE_EBOOK_REVIEW, {
      createOrUpdateEbookReviewInput: { ebookId, star, content },
    })
    return resp.createEbookReview.status
  }

  async function uploadFile(
    file: File | Blob,
    resizeType?: ResizeTypeEnum
  ): Promise<{
    filePath: string
    filePathResize: string
  }> {
    const {
      UploadBookCover: { filePath, filePathResize },
    } = await gqlApiInstance.request(UPLOAD_FILE, {
      file,
      resizeType,
    })

    return { filePath, filePathResize }
  }

  async function getStoryById(id: number): Promise<EbookManagementType> {
    const { book } = await gqlApiInstance.request(GET_STORY, { id })

    return plainToInstance(EbookManagementType, {
      detail: book,
      setting: {},
      ebookStep: EbookStepEnum.DETAIL,
    })
  }

  async function getMyEbookById(ebookId: number): Promise<EbookManagementType> {
    const { getMyEbook } = await gqlApiInstance.request(GET_MY_EBOOK, {
      ebookId,
    })

    return plainToInstance(EbookManagementType, {
      detail: getMyEbook,
      setting: getMyEbook,
      ebookStep: getMyEbook.ebookStep,
    })
  }

  async function createEbookHandle({
    ebookDetail,
    ebookSettingDetail,
    ebookStatus,
    bookId,
  }: {
    ebookDetail: EbookDetailFormType
    ebookSettingDetail: SettingEbookFormType
    ebookStatus: EbookStatusEnum
    bookId: number
  }): Promise<{ id: number; tags: string[] }> {
    let coverImgPath = {
      filePath: ebookDetail.coverImgPath.url,
      filePathResize: '',
    }
    try {
      if (ebookDetail.coverImgPath?.blob) {
        coverImgPath = await uploadFile(
          ebookDetail.coverImgPath.blob,
          ResizeTypeEnum.BOOK
        )
      }

      const createEbookInput = await tranformCreateEbook(
        ebookDetail,
        ebookSettingDetail,
        bookId
      )
      const { createEbook } = await gqlApiInstance.request(CREATE_EBOOK, {
        createEbookInput: {
          ...createEbookInput,
          ...(coverImgPath.filePath && { coverImgPath: coverImgPath.filePath }),
          ...(coverImgPath.filePath && {
            coverResizeImgPath:
              coverImgPath.filePathResize || coverImgPath.filePath,
          }),
          ebookStatus,
        },
      })

      return {
        id: createEbook.id,
        tags: createEbook.tags.map((row: { name: string }) => row.name),
      }
    } catch (error) {
      throw error
    }
  }

  async function updateEbook({
    ebookDetail,
    ebookSettingDetail,
    bookId,
  }: {
    ebookDetail: EbookDetailFormType
    ebookSettingDetail: SettingEbookFormType
    bookId: number
  }): Promise<string[]> {
    let coverImgPath = {
      filePath: ebookDetail.coverImgPath.url,
      filePathResize: '',
    }
    try {
      if (ebookDetail.coverImgPath?.blob) {
        coverImgPath = await uploadFile(
          ebookDetail.coverImgPath.blob,
          ResizeTypeEnum.BOOK
        )
      }

      const res = await gqlApiInstance.request(UPDATE_EBOOK, {
        updateEbookInput: {
          bookId,
          bookType: ebookDetail.bookType,
          categoryId: ebookDetail.categoryId,
          cover: ebookDetail.cover,
          epubFilePath: '',
          epubTrialFilePath: '',
          hasTrial: ebookSettingDetail.hasTrial,
          isCheckAge: ebookSettingDetail.isCheckAge,
          intro: ebookDetail.intro,
          offlineReadable: true,
          orientation: ebookDetail.orientation,
          penNameId: ebookDetail.penNameId,
          ratingId: ebookDetail.ratingId,
          screenCapturable: true,
          isbnNo: ebookDetail.isbnNo ? ebookDetail.isbnNo : undefined,
          tagNames: ebookDetail.tags,
          title: ebookDetail.title,
          trialChapterAmount: ebookSettingDetail.testBookChapterInput,
          userId: ebookDetail.penNameUserId,
          writer: ebookDetail.writer,
          isTranslated: ebookDetail.isTranslated,
          writingType: ebookDetail.writingType,
          ...(coverImgPath.filePath && { coverImgPath: coverImgPath.filePath }),
          ...(coverImgPath.filePath && {
            coverResizeImgPath:
              coverImgPath.filePathResize || coverImgPath.filePath,
          }),
          id: ebookDetail.id,
          ebookStatus: ebookDetail.ebookStatus,
        },
      })

      return res.updateEbook.tags.map((row: { name: string }) => row.name)
    } catch (error) {
      throw error
    }
  }

  async function bookChapterForCreate(
    ebookId: number
  ): Promise<BookChapterForCreateType[]> {
    const { getBookChapterForCreate } = await gqlApiInstance.request(
      GET_BOOK_CHAPTER_FOT_CREATE,
      {
        getBookChapterForCreateArgs: {
          ebookId,
          orderBy: {
            createdAt: SortEnum.ASC,
          },
        },
      }
    )

    return plainToInstance(
      BookChapterForCreateType,
      getBookChapterForCreate as []
    )
  }

  async function createChapterFromBook(
    ebookId: number,
    ebookChapterCreateIds: number[]
  ): Promise<void> {
    await gqlApiInstance.request(CREATE_CHAPTER_FORM_BOOK, {
      createEbookChapterFromBookInput: {
        ebookId,
        ebookChapterCreateIds,
      },
    })
  }

  async function deleteEbookChapter({
    ebookId,
    ebookChapterRemoveIds,
    bookChapterRemoveIds,
  }: {
    ebookId: number
    ebookChapterRemoveIds?: number[]
    bookChapterRemoveIds?: number[]
  }): Promise<void> {
    await gqlApiInstance.request(DELETE_EBOOK_CHAPTER, {
      removeEbookChapterInput: {
        ebookId,
        ebookChapterRemoveIds,
        bookChapterRemoveIds,
      },
    })
  }

  async function updateEbookSorting(
    ebookId: number,
    chapters: EbookChapterType[]
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_EBOOK_SORTING, {
      updateNewEbookChapterSorting: {
        ebookId,
        updateEbookChapterArg: chapters.map((item, index) => {
          return {
            id: item.id,
            runningNo: index + 1,
          }
        }),
      },
    })
  }

  async function ebookChapters(ebookId: number): Promise<EbookChapterType[]> {
    const { getEbookChapters } = await gqlApiInstance.request(
      GET_EBOOK_CHAPTERS,
      {
        ebookId,
      }
    )
    return plainToInstance(EbookChapterType, getEbookChapters as [])
  }

  async function ebookChapter(
    ebookId: number,
    chapterId: number
  ): Promise<EbookChapterFormType> {
    const { getEbookChapter } = await gqlApiInstance.request(
      GET_EBOOK_CHAPTER,
      {
        ebookId,
        chapterId,
      }
    )
    return plainToInstance(EbookChapterFormType, getEbookChapter)
  }

  async function createEbookChapter(
    ebookId: number,
    form: EbookChapterFormType
  ): Promise<void> {
    await gqlApiInstance.request(CREATE_EBOOK_CHAPTER, {
      createNewEbookChapterInput: {
        createEbookChapterPageInput: form.chapterPages?.map(
          (chapterPage, index) => {
            return {
              content: chapterPage.content,
              imgPath: chapterPage.imgPath,
              ordered: index + 1,
              size: chapterPage.size,
            }
          }
        ),
        chapterName: form.chapterName,
        chapterTitle: form.chapterTitle,
        ebookId,
        chapterCoverImgPath: form.chapterCoverImgPath.url || '',
        writerMessage: form.writerMessage || '',
      },
    })
  }

  async function updateEbookChapter(
    ebookId: number,
    form: EbookChapterFormType
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_EBOOK_CHAPTER, {
      updateEbookChapterInputType: {
        chapterCoverImgPath: form.chapterCoverImgPath.url || '',
        chapterId: form.chapterId,
        chapterName: form.chapterName,
        chapterTitle: form.chapterTitle,
        ebookId,
        writerMessage: form.writerMessage || '',
        updateEbookChapterPageInput: form.chapterPages?.map(
          (chapterPage, index) => {
            return {
              content: chapterPage.content,
              imgPath: chapterPage.imgPath,
              ordered: index + 1,
              size: chapterPage.size,
            }
          }
        ),
      },
    })
  }

  async function updateEbookSetting(
    ebookId: number,
    form: SettingEbookFormType,
    chapter: EbookChapterType[],
    ebookStatus: EbookStatusEnum
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_EBOOK_SETTING, {
      updateEbookSettingInput: {
        ebookReviewStatus: form.ebookReviewStatus,
        id: ebookId,
        publishedAt:
          form.publishedType === PublishedTypeEnum.NOW
            ? new Date()
            : form.publishedAt,
        updateChapterFreeIds: form.hasTrial
          ? form.testBooktype === EbookTestBookEnum.SELECT_CHAPTER
            ? form.trailChapters
            : chapter.slice(0, form.testBookChapterInput).map(item => item.id)
          : undefined,
        hasTrial: form.hasTrial,
        isCheckAge: form.isCheckAge,
        ebookChapterSelectType: form.testBooktype,
        ebookStatus,
      },
    })
  }

  async function updateEbookStatus(
    ebookId: number,
    ebookStatus: EbookStatusEnum,
    publishedAt?: DateTime
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_EBOOK_SETTING, {
      updateEbookSettingInput: {
        id: ebookId,
        ebookStatus,
        ...(publishedAt && { publishedAt: publishedAt.toUTC() }),
      },
    })
  }

  async function removeEbook(ebookId: number): Promise<void> {
    await gqlApiInstance.request(REMOVE_EBOOK, {
      ebookId,
    })
  }

  async function createPromotionEbook(
    ebookId: number,
    form: SettingEbookFormType
  ): Promise<void> {
    const { promotion } = form
    await gqlApiInstance.request(CREATE_PROMOTION_EBOOK, {
      createPromotionEbookInput: {
        ebookId,
        isPublish: promotion.isPublish,
        price: form.price,
        endPublishedAt: promotion.isPublish
          ? promotion.endPublishedAt
          : undefined,
        startPublishedAt: promotion.isPublish
          ? promotion.startPublishedAt
          : undefined,
        discountCoin: promotion.isPublish ? promotion.coinDiscount : undefined,
        discountPercent: promotion.isPublish
          ? promotion.percentDiscount
          : undefined,
      },
    })
  }

  async function updatePromotionEbook(
    ebookId: number,
    form: SettingEbookFormType
  ): Promise<void> {
    const { promotion } = form
    await gqlApiInstance.request(UPDATE_PROMOTION_EBOOK, {
      updatePromotionEbookInput: {
        isPublish: promotion.isPublish,
        price: form.price,
        promotionId: promotion.id,
        ebookId,
        endPublishedAt: promotion.isPublish
          ? promotion.endPublishedAt
          : undefined,
        startPublishedAt: promotion.isPublish
          ? promotion.startPublishedAt
          : undefined,
        discountCoin: promotion.isPublish ? promotion.coinDiscount : undefined,
        discountPercent: promotion.isPublish
          ? promotion.percentDiscount
          : undefined,
      },
    })
  }

  async function updateEbookSeriesPromotion(
    form: UpdatePromotionEbookFormType
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_PROMOTION_EBOOK, {
      updatePromotionEbookInput: {
        ...form,
      },
    })
  }

  async function tranformCreateEbook(
    ebookDetail: EbookDetailFormType,
    ebookSettingDetail: SettingEbookFormType,
    bookId: number
  ) {
    return {
      bookId,
      bookType: ebookDetail.bookType,
      categoryId: ebookDetail.categoryId,
      cover: ebookDetail.cover,
      epubFilePath: '',
      epubTrialFilePath: '',
      hasTrial: ebookSettingDetail.hasTrial,
      isCheckAge: ebookSettingDetail.isCheckAge,
      intro: ebookDetail.intro,
      offlineReadable: true,
      orientation: ebookDetail.orientation,
      penNameId: ebookDetail.penNameId,
      publishedAt:
        ebookSettingDetail.publishedType === PublishedTypeEnum.NOW
          ? new Date()
          : ebookSettingDetail.publishedAt,
      ratingId: ebookDetail.ratingId,
      screenCapturable: true,
      isbnNo: ebookDetail.isbnNo ? ebookDetail.isbnNo : undefined,
      tagNames: ebookDetail.tags,
      title: ebookDetail.title,
      trialChapterAmount: ebookSettingDetail.testBookChapterInput,
      userId: ebookDetail.penNameUserId,
      writer: ebookDetail.writer,
      isTranslated: ebookDetail.isTranslated,
      writingType: ebookDetail.writingType,
    }
  }

  async function searchWritingEbookProfile({
    userId,
    page,
    limitPerPage,
    bookType,
    penNameId,
  }: {
    userId: number
    page: number
    limitPerPage: number
    bookType: BookEnum[] | null
    penNameId: number | null
  }): Promise<PaginatedEbookProfile> {
    const res = await gqlApiInstance.request(SEARCH_WRITING_EBOOK_PROFILE, {
      userId,
      page,
      limitPerPage,
      bookType,
      penNameId,
    })

    return plainToInstance(PaginatedEbookProfile, res.searchWritingEbookProfile)
  }

  async function getBookDetail(
    bookId: number
  ): Promise<BookDetailTypeResponse> {
    const res = await gqlApiInstance.request(GET_BOOK_DETAIL, {
      bookId,
    })

    return plainToInstance(BookDetailTypeResponse, res.getBookDetail)
  }

  async function getEbookSeries(bookId: number): Promise<EbookSeriesType[]> {
    const res = await gqlApiInstance.request(GET_EBOOK_SERIES, {
      getEBookSeriesArgs: {
        bookId,
        orderBy: {
          createdAt: SortEnum.ASC,
        },
      },
    })

    return plainToInstance(EbookSeriesType, res.getEbookSeries as [])
  }

  async function getEbookPromotionOnSale({
    limitPerPage,
    page,
  }: {
    limitPerPage: number
    page?: number
  }): Promise<EbookPromotionOnSaleResponse> {
    const { searchEbookPromotionOnSale } = await gqlApiInstance.request(
      SEARCH_EBOOK_PROMOTION_ON_SALE,
      {
        limitPerPage,
        page,
      }
    )

    return plainToInstance(
      EbookPromotionOnSaleResponse,
      searchEbookPromotionOnSale
    )
  }

  async function getOtherEbooks({
    bookId,
    ebookId,
  }: {
    bookId: number
    ebookId: number
  }): Promise<MyOtherEbooksType[]> {
    const res = await gqlApiInstance.request(GET_OTHER_EBOOKS, {
      bookId,
      ebookId,
    })

    return plainToInstance(MyOtherEbooksType, res.getOtherEbookInSeries as [])
  }

  async function searchEbook(
    params: SearchEbookParam
  ): Promise<SearchEbookResponse> {
    const resp = await gqlApiInstance.request(SEARCH_EBOOK, {
      limitPerPage: params.limitPerPage,
      page: params.page,
      bookType: params.bookType,
      categoryIds: params.categoryIds,
      searchText: params.searchText,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      ebookSearchBy: params.ebookSearchBy,
    })

    return plainToInstance(SearchEbookResponse, resp.searchEbook)
  }

  async function updateEbookStep(
    ebookId: number,
    ebookStep: EbookStepEnum
  ): Promise<void> {
    await gqlApiInstance.request(UPDATE_EBOOK_STEP, {
      updateEbookStep: {
        ebookId,
        ebookStep,
      },
    })
  }
  async function getCategoryEbookList({
    allCategoryEbookSearchParam,
    page,
    perpage,
  }: {
    allCategoryEbookSearchParam: AllCategoryEbookSearchType
    page: number
    perpage: number
  }): Promise<SearchCategoryEbookResponse> {
    const [key, val, hitScope] = allCategoryEbookSearchParam.orderBy.split(':')

    const { ebookAdvanceSearchPaginate } = await gqlApiInstance.request(
      GET_CATEGORY_EBOOK_LIST,
      {
        limitPerPage: perpage,
        page,
        bookType: allCategoryEbookSearchParam.secondaryType
          ? allCategoryEbookSearchParam.secondaryType
          : undefined,
        categoryId: allCategoryEbookSearchParam.categoryId
          ? Number(allCategoryEbookSearchParam.categoryId)
          : undefined,
        searchType: allCategoryEbookSearchParam.primaryType,
        orderBy: {
          [key]: val,
        },
        hitScope,
      }
    )

    return plainToInstance(
      SearchCategoryEbookResponse,
      ebookAdvanceSearchPaginate
    )
  }

  async function getEbookWriterDetail(
    ebookId: number
  ): Promise<EbookDetailType> {
    const { getMyEbook } = await gqlApiInstance.request(
      GET_EBOOK_WRITER_DETAIL,
      {
        ebookId,
      }
    )

    return plainToInstance(EbookDetailType, getMyEbook)
  }

  async function seriesEbookReader({
    bookId,
    page,
    limitPerPage,
  }: {
    bookId: number
    page: number
    limitPerPage: number
  }): Promise<ReaderSeriesRespone> {
    const { getEbookReader } = await gqlApiInstance.request(GET_EBOOK_READER, {
      bookId,
      limitPerPage,
      page,
    })
    return plainToInstance(ReaderSeriesRespone, getEbookReader)
  }

  async function updateEbooksManage({
    ids,
    ebookStatus,
    publishedAt,
    isDelete,
    isCancelPublishedAt,
  }: {
    ids: number[]
    ebookStatus?: EbookStatusEnum
    publishedAt?: DateTime
    isDelete?: boolean
    isCancelPublishedAt?: boolean
  }) {
    await gqlApiInstance.request(UPDATE_EBOOK_SETTING_IDS, {
      updateEbookSettingIdsInput: {
        ids,
        ...(ebookStatus && { ebookStatus }),
        ...(publishedAt && { publishedAt: publishedAt.toUTC() }),
        ...(isCancelPublishedAt === true && { isCancelPublishedAt }),
        ...(isDelete === true && { isDelete }),
      },
    })
  }

  async function ebookUpdatePrice({
    id,
    price,
  }: {
    id: number
    price: number
  }) {
    await gqlApiInstance.request(UPDATE_EBOOK_SETTING_IDS, {
      updateEbookSettingIdsInput: {
        ebookUpDatePrice: {
          id,
          price,
        },
      },
    })
  }

  async function ebookFreeTrialChapterOptions({
    ebookId,
    page,
    limitPerPage,
  }: {
    ebookId: number
    page: number
    limitPerPage: number
  }): Promise<EbookFreeTrialOptionsResponse> {
    const { freeTrialEbookChapterListPaginate } = await gqlApiInstance.request(
      GET_FREE_TRIAL_EBOOK_LIST,
      {
        ebookId,
        page,
        limitPerPage,
      }
    )

    return plainToInstance(
      EbookFreeTrialOptionsResponse,
      freeTrialEbookChapterListPaginate
    )
  }

  async function ebookFreeTrialReader(
    chapterId: number
  ): Promise<EbookFreeTrialChapterType> {
    const { freeTrialEbookChapterPageContent } = await gqlApiInstance.request(
      GET_FREE_TRIAL_EBOOK_CHAPTER_READER,
      {
        chapterId,
      }
    )

    return plainToInstance(
      EbookFreeTrialChapterType,
      freeTrialEbookChapterPageContent
    )
  }

  async function buyEbook(
    { ebookId, goldCoin, silverCoin, thirdCoin }: BuyEbookFormType,
    userCoin: UserCoin,
    price: number
  ) {
    const coins = []
    const gold = clearComma(goldCoin)
    const silver = clearComma(silverCoin)
    const third = thirdCoin ? clearComma(thirdCoin) : 0

    if (price !== 0) {
      if (gold) {
        coins.push({
          coinId: userCoin.paidCoin?.id,
          coinValue: gold,
          coinType: userCoin.paidCoin?.type,
        })
      }

      if (silver) {
        coins.push({
          coinId: userCoin.freeCoin?.id,
          coinValue: silver,
          coinType: userCoin.freeCoin?.type,
        })
      }

      if (third) {
        coins.push({
          coinId: userCoin.thirdCoin?.id,
          coinValue: third,
          coinType: userCoin.thirdCoin?.type,
        })
      }
    } else {
      coins.push({
        coinId: userCoin.paidCoin?.id,
        coinValue: 0,
        coinType: userCoin.paidCoin?.type,
      })
    }

    await gqlApiInstance.request(BUY_EBOOK, {
      buyEbookInput: {
        ebookId,
        coins,
      },
    })
  }

  async function getEbookNewestList({
    page,
    limitPerPage,
  }: {
    page: number
    limitPerPage: number
  }): Promise<SearchCategoryEbookResponse> {
    const { ebookAdvanceSearchPaginate } = await gqlApiInstance.request(
      GET_EBOOK_NEWEST,
      {
        page,
        limitPerPage,
        searchType: EbookSearchTypeEnum.NEW_EBOOK,
        orderBy: {
          publishedAt: SortEnum.DESC,
        },
      }
    )

    return plainToInstance(
      SearchCategoryEbookResponse,
      ebookAdvanceSearchPaginate
    )
  }

  async function getRecommendationEbooks({
    recommendType,
    recommendLimit,
  }: {
    recommendType: EbookRecommendEnum
    recommendLimit: number
  }): Promise<RecommendEbookType[]> {
    const { recommendEbook } = await gqlApiInstance.request(
      GET_RECOMMEND_EBOOK,
      {
        recommendType,
        recommendLimit,
      }
    )

    return plainToInstance(RecommendEbookType, recommendEbook as [])
  }

  async function getTotalPageAndWordCount(
    ebookId: number
  ): Promise<TotalPageAndWordCountType> {
    const { getMyEbook } = await gqlApiInstance.request(
      GET_TOTAL_PAGE_AND_WORD_COUNT,
      {
        ebookId,
      }
    )

    return plainToInstance(TotalPageAndWordCountType, getMyEbook)
  }

  async function updateEbookReviewVisible({
    id,
    visible,
  }: {
    id: number
    visible: boolean
  }): Promise<string> {
    const res = await gqlApiInstance.request(UPDATE_EBOOK_REVIEW_VISIBLE, {
      updateEbookReviewVisibleInput: {
        id,
        visible,
      },
    })

    return res.updateEbookReviewVisible.message
  }

  return {
    getStoryById,
    // bookChaptersEbook,
    createEbookHandle,
    bookChapterForCreate,
    createChapterFromBook,
    deleteEbookChapter,
    ebookChapters,
    updateEbookSorting,
    ebookChapter,
    createEbookChapter,
    updateEbookChapter,
    getMyEbookById,
    updateEbookSetting,
    updateEbookStatus,
    removeEbook,
    createPromotionEbook,
    updatePromotionEbook,
    updateEbookSeriesPromotion,
    searchWritingEbookProfile,
    getBookDetail,
    getEbookSeries,
    getEbookPromotionOnSale,
    getEbookReaderDetail,
    getEbookReviewPaginate,
    createEbookReview,
    getOtherEbooks,
    searchEbook,
    updateEbookStep,
    getCategoryEbookList,
    getEbookWriterDetail,
    seriesEbookReader,
    updateEbooksManage,
    ebookUpdatePrice,
    ebookFreeTrialChapterOptions,
    ebookFreeTrialReader,
    buyEbook,
    getEbookNewestList,
    getRecommendationEbooks,
    getTotalPageAndWordCount,
    updateEbookReviewVisible,
    updateEbook,
  }
}
