import { gql } from 'graphql-request'

export const GET_TOTAL_PAGE_AND_WORD_COUNT = gql`
  query GetTotalPageAndWordCount($ebookId: Float!) {
    getMyEbook(ebookId: $ebookId) {
      totalWordCount
      totalPageCount
    }
  }
`
