import { gql } from 'graphql-request'

export const DELETE_EBOOK_CHAPTER = gql`
  mutation DeleteEbookChapter(
    $removeEbookChapterInput: RemoveEbookChapterInput!
  ) {
    deleteEbookChapter(RemoveEbookChapterInput: $removeEbookChapterInput) {
      bookChapterId
    }
  }
`
