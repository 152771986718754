import { Expose, Transform, Type } from 'class-transformer'
import { CategoryType } from '@models/category/CategoryType'

import { TagType } from '@models/story/TagType'
import { BookEnum } from '@interfaces/BookEnum'
import { PenNameType } from '@models/penName/PenNameType'
import { PromotionEbookType } from './PromotionEbookType'

export class SearchCategoryEbookType {
  @Expose()
  id: number

  @Expose()
  coverImgPath: string

  @Expose()
  title: string

  @Expose()
  cover: string

  @Expose()
  penName: PenNameType

  @Expose()
  @Type(() => TagType)
  tags: TagType[]

  @Expose()
  category: CategoryType

  @Expose()
  @Transform(({ value }) => value || 0)
  wishlistCount: number

  @Expose()
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value ?? false)
  isWishlist: boolean

  @Expose()
  publishedAt: string

  @Expose()
  @Type(() => PromotionEbookType)
  promotion?: PromotionEbookType

  @Expose() avgRating: number

  @Expose() price: number

  @Expose() reviewCount: number
}
