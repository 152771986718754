import { gql } from 'graphql-request'

export const GET_EBOOK_WRITER_DETAIL = gql`
  query GetMyEbook($ebookId: Float!) {
    getMyEbook(ebookId: $ebookId) {
      id
      coverImgPath
      title
      writer
      cover
      bookType
      writingType
      isTranslated
      wishlistCount
      viewCount
      reviewCount
      lastCommentDate
      intro
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      penName {
        id
        firstPenName
        facebookLink
        twitterLink
        user {
          id
          displayName
          avatarImgPath
          profilePageSlug
          writer {
            affiliation
            status
          }
          email
          isAdmin
        }
      }
      rating {
        id
        name
      }
      tags {
        id
        name
      }
      category {
        id
        name
      }
      publishedAt
      createdAt
      updatedAt
      dynamicLink
      facebookShareImageUrl
      twitterShareImageUrl
      requireAgeVerify
      isbnNo
      promotion {
        id
        discountPrice
        startPublishedAt
        endPublishedAt
        price
        isPublish
        discountCoin
        discountPercent
      }
      avgRating
      price
      ebookReviewStatus
      ebookChapters {
        id
        isTrial
      }
      totalPageCount
      totalWordCount
      chapterCount
      ebookStatus
      isVisible
      ebookStep
    }
  }
`
