import { EbookReviewEnum } from '@interfaces/EbookReviewEnum'
import { EbookTestBookEnum } from '@interfaces/EbookTestBookEnum'
import { PublishedTypeEnum } from '@interfaces/PublishedTypeEnum'
import { Expose, Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'
import { DAY, rateRIds } from '@lib/utils'

export class SettingEbookFormType {
  @Expose()
  @Transform(({ value }) => value ?? true)
  hasTrial: boolean

  @Expose()
  @Transform(({ value }) => value ?? false)
  isCheckAge: boolean

  @Expose()
  @Transform(({ obj }) => !rateRIds.includes(obj.rating?.id))
  disableCheckAge: boolean

  @Expose({ name: 'ebookChapterSelectType' })
  @Transform(({ value }) => value ?? EbookTestBookEnum.SPECIFY_AMOUNT_CHAPTER)
  testBooktype: EbookTestBookEnum

  @Expose()
  @Transform(({ value }) => value ?? 0)
  testBookChapterInput: number

  @Expose()
  @Transform(({ value }) => value ?? EbookReviewEnum.BUYER_PERMITTED_TO_REVIEW)
  ebookReviewStatus: EbookReviewEnum

  @Expose()
  @Transform(({ obj }) => {
    if (obj.publishedAt) {
      const now = DateTime.now()
      const d2 = DateTime.fromISO(obj.publishedAt)
      if (now > d2) return PublishedTypeEnum.NOW
      return PublishedTypeEnum.PERIOD
    }

    return PublishedTypeEnum.NOW
  })
  publishedType: PublishedTypeEnum

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : new Date()))
  publishedAt: Date

  @Expose()
  @Transform(() => [])
  trailChapters: number[]

  @Expose()
  @Transform(({ value }) => value ?? 0)
  price: number

  @Expose({ name: 'promotions' })
  @Transform(({ value }) =>
    value?.length > 0
      ? value[0]
      : {
          isPublish: false,
          coinDiscount: 0,
          percentDiscount: 0,
          startPublishedAt: new Date(),
          endPublishedAt: new Date(Date.now() + DAY * 30),
        }
  )
  @Type(() => EbookPromotionType)
  promotion: EbookPromotionType
}

class EbookPromotionType {
  @Expose()
  id?: number

  @Expose()
  @Transform(({ value }) => value ?? false)
  isPublish: boolean

  @Expose({ name: 'discountCoin' })
  @Transform(({ value }) => value ?? 0)
  coinDiscount: number

  @Expose({ name: 'discountPercent' })
  @Transform(({ value }) => value ?? 0)
  percentDiscount: number

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : new Date()))
  startPublishedAt: Date

  @Expose()
  @Transform(({ value }) =>
    value ? new Date(value) : new Date(Date.now() + DAY * 30)
  )
  endPublishedAt: Date
}
