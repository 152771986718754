import { gql } from 'graphql-request'

export const UPDATE_EBOOK_CHAPTER = gql`
  mutation UpdateEbookChapter(
    $updateEbookChapterInputType: UpdateEbookChapterInputType!
  ) {
    updateEbookChapter(
      UpdateEbookChapterInputType: $updateEbookChapterInputType
    ) {
      id
    }
  }
`
