import { gql } from 'graphql-request'

export const SEARCH_EBOOK = gql`
  query SearchEbook(
    $limitPerPage: Int
    $page: Int
    $searchText: String
    $ebookSearchBy: EbookSearchBy
    $bookType: [BookType!]
    $categoryIds: [Int!]
    $orderBy: OrderedBy
    $orderDirection: OrderDirection
  ) {
    searchEbook(
      limitPerPage: $limitPerPage
      page: $page
      searchText: $searchText
      ebookSearchBy: $ebookSearchBy
      bookType: $bookType
      categoryIds: $categoryIds
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        id
        title
        cover
        tags {
          id
          name
        }
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            profilePageSlug
          }
        }
        coverImgPath
        coverResizeImgPath
        bookType
        publishedAt
        writingType
        isTranslated
        isWishlist
        wishlistCount
        promotion {
          id
          discountPrice
          startPublishedAt
          endPublishedAt
          price
          isPublish
          discountPercent
        }
        price
        avgRating
        reviewCount
      }
      page
      total
    }
  }
`
