import { gql } from 'graphql-request'

export const SEARCH_EBOOK_PROMOTION_ON_SALE = gql`
  query SearchEbookPromotionOnSale($limitPerPage: Int, $page: Int) {
    searchEbookPromotionOnSale(limitPerPage: $limitPerPage, page: $page) {
      data {
        id
        title
        cover
        writer
        coverImgPath
        coverResizeImgPath
        penName {
          firstPenName
          user {
            profilePageSlug
          }
        }
        bookType
        category {
          id
          name
        }
        tags {
          id
          name
        }
        avgRating
        reviewCount
        likeCount
        wishlistCount
        isWishlist
        promotion {
          id
          price
          discountPrice
          discountCoin
          discountPercent
          startPublishedAt
          endPublishedAt
          isPublish
        }
      }
      page
      total
    }
  }
`
