import { Expose, Type } from 'class-transformer'
import { EbookPromotionOnSaleType } from './EbookPromotionOnSaleType'

export class EbookPromotionOnSaleResponse {
  @Expose()
  @Type(() => EbookPromotionOnSaleType)
  data: EbookPromotionOnSaleType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
