import { gql } from 'graphql-request'

export const GET_FREE_TRIAL_EBOOK_CHAPTER_READER = gql`
  query FreeTrialEbookChapterPageContent($chapterId: Int!) {
    freeTrialEbookChapterPageContent(chapterId: $chapterId) {
      id
      chapterName
      chapterTitle
      writerMessage
      prevId
      nextId
      isTrial
      publishedAt
      ebookChapterPages {
        id
        imgPath
        content
        ordered
      }
      ebook {
        id
        title
        publishedAt
        penName {
          id
          firstPenName
          user {
            id
            displayName
            avatarImgPath
            profilePageSlug
            writer {
              affiliation
              status
            }
            isAdmin
            email
          }
        }
        orientation
        bookType
        screenCapturable
        ebookStatus
        coverImgPath
        requireAgeVerify
        rating {
          id
        }
      }
    }
  }
`
