import { Expose, Type } from 'class-transformer'
import { EbookPromotionOnSaleType } from './EbookPromotionOnSaleType'

export class ReaderSeriesRespone {
  @Expose()
  @Type(() => EbookPromotionOnSaleType)
  data: EbookPromotionOnSaleType[]

  @Expose() page: number

  @Expose() total: number
}
