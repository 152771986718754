import { gql } from 'graphql-request'

export const UPDATE_PROMOTION_EBOOK = gql`
  mutation UpdatePromotionEbook(
    $updatePromotionEbookInput: UpdatePromotionEbookInput!
  ) {
    updatePromotionEbook(
      updatePromotionEbookInput: $updatePromotionEbookInput
    ) {
      message
    }
  }
`
