import { gql } from 'graphql-request'

export const GET_EBOOK_REVIEW_PAGINATE = gql`
  query GetEbookReviewPaginate($limitPerPage: Int, $page: Int, $ebookId: Int!) {
    getEbookReviewPaginate(
      limitPerPage: $limitPerPage
      page: $page
      ebookId: $ebookId
    ) {
      data {
        id
        content
        star
        user {
          id
          avatarImgPath
          displayName
          profilePageSlug
          isAdmin
          email
        }
        updatedAt
        visible
      }
      total
      page
    }
  }
`
