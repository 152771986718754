import { gql } from 'graphql-request'

export const CREATE_EBOOK = gql`
  mutation CreateEbook($createEbookInput: CreateEbookInput!) {
    createEbook(CreateEbookInput: $createEbookInput) {
      id
      tags {
        name
      }
    }
  }
`
