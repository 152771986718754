import { gql } from 'graphql-request'

export const GET_FREE_TRIAL_EBOOK_LIST = gql`
  query FreeTrialEbookChapterListPaginate(
    $ebookId: Int!
    $limitPerPage: Int
    $page: Int
  ) {
    freeTrialEbookChapterListPaginate(
      ebookId: $ebookId
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        id
        chapterName
        chapterTitle
      }
      page
    }
  }
`
