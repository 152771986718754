import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { ChapterPageFreeTrialType } from './ChapterPageFreeTrialType'
import { EbookFreeTrialType } from './EbookFreeTrialType'

@Exclude()
export class EbookFreeTrialChapterType {
  @Expose() id: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose()
  @Transform(({ value }) => (value === '<p></p>' ? '' : value))
  writerMessage: string

  @Expose() prevId: number | null

  @Expose() nextId: number | null

  @Expose() isTrial: boolean

  @Expose() publishedAt: string

  @Expose()
  @Type(() => ChapterPageFreeTrialType)
  ebookChapterPages: ChapterPageFreeTrialType[]

  @Expose()
  @Type(() => EbookFreeTrialType)
  ebook: EbookFreeTrialType
}
