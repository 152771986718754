import { Expose, Type } from 'class-transformer'
import { EbookReviewType } from './EbookReviewType'

export class EbookReviewResponse {
  @Expose()
  @Type(() => EbookReviewType)
  data: EbookReviewType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
