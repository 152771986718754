import { Expose, Type } from 'class-transformer'

import { EbookStepEnum } from '@interfaces/EbookStepEnum'
import { EbookDetailFormType } from './EbookDetailFormType'
import { SettingEbookFormType } from './SettingEbookFormType'

export class EbookManagementType {
  @Expose()
  @Type(() => EbookDetailFormType)
  detail: EbookDetailFormType

  @Expose()
  @Type(() => SettingEbookFormType)
  setting: SettingEbookFormType

  @Expose()
  ebookStep: EbookStepEnum
}
