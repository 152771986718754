import { gql } from 'graphql-request'

export const UPLOAD_FILE = gql`
  mutation UploadBookCover($resizeType: ResizeType, $file: Upload!) {
    UploadBookCover(resizeType: $resizeType, file: $file) {
      filePath
      filePathResize
    }
  }
`
