import { gql } from 'graphql-request'

export const GET_EBOOK_SERIES = gql`
  query GetEbookSeries($getEBookSeriesArgs: GetEBookSeriesArgs!) {
    getEbookSeries(GetEBookSeriesArgs: $getEBookSeriesArgs) {
      id
      title
      bookType
      coverImgPath
      coverResizeImgPath
      chapterCount
      purchaseCount
      avgRating
      reviewCount
      wishlistCount
      promotion {
        id
        isPublish
        price
        discountPrice
        discountPercent
        discountCoin
        startPublishedAt
        endPublishedAt
      }
      price
      publishedAt
      ebookStatus
      ebookStep
      isVisible
    }
  }
`
