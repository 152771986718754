import { Expose, Transform } from 'class-transformer'
import type { ImageType } from '@interfaces/ImageType'
import { ChapterPageType } from '@models/myWriting/ChapterPageType'

export class EbookChapterFormType {
  @Expose()
  @Transform(
    ({ value }) => {
      return value || undefined
    },
    { toPlainOnly: true }
  )
  chapterId?: number

  @Expose()
  chapterTitle: string

  @Expose()
  chapterName: string

  @Expose()
  @Transform(
    ({ value }) => {
      return value || undefined
    },
    { toPlainOnly: true }
  )
  writerMessage: string

  @Expose()
  @Transform(
    ({ obj }) => {
      return {
        url: obj?.chapterCoverImgPath,
      }
    },
    { toClassOnly: true }
  )
  @Transform(
    ({ value }) => {
      return value?.url || undefined
    },
    { toPlainOnly: true }
  )
  chapterCoverImgPath: ImageType

  @Expose({ name: 'ebookChapterPages' })
  @Transform(
    ({ value }) => {
      return value?.map((chapterPage: ChapterPageType, index: number) => {
        return {
          id: chapterPage.id || undefined,
          content: chapterPage.content,
          imgPath: chapterPage.imgPath,
          ordered: index + 1,
          size: chapterPage.size,
        }
      })
    },
    { toPlainOnly: true }
  )
  chapterPages: ChapterPageType[]
}
