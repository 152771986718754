import { gql } from 'graphql-request'

export const UPDATE_EBOOK_SETTING_IDS = gql`
  mutation UpdateEbookSettingIds(
    $updateEbookSettingIdsInput: UpdateEbookSettingIdsInput!
  ) {
    updateEbookSettingIds(
      UpdateEbookSettingIdsInput: $updateEbookSettingIdsInput
    ) {
      message
    }
  }
`
