import { gql } from 'graphql-request'

export const UPDATE_EBOOK_REVIEW_VISIBLE = gql`
  mutation UpdateEbookReviewVisible(
    $updateEbookReviewVisibleInput: UpdateEbookReviewVisibleInput!
  ) {
    updateEbookReviewVisible(
      updateEbookReviewVisibleInput: $updateEbookReviewVisibleInput
    ) {
      message
    }
  }
`
