import { gql } from 'graphql-request'

export const CREATE_EBOOK_REVIEW = gql`
  mutation CreateEbookReview(
    $createOrUpdateEbookReviewInput: CreateOrUpdateEbookReviewInput!
  ) {
    createEbookReview(
      CreateOrUpdateEbookReviewInput: $createOrUpdateEbookReviewInput
    ) {
      status
    }
  }
`
