import { gql } from 'graphql-request'

export const GET_EBOOK_CHAPTERS = gql`
  query GetEbookChapters($ebookId: Int!) {
    getEbookChapters(ebookId: $ebookId) {
      bookChapterId
      isEdited
      isTrial
      updatedAt
      id
      chapterTitle
      chapterName
      runningNo
    }
  }
`
