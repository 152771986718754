import { gql } from 'graphql-request'

export const CREATE_EBOOK_CHAPTER = gql`
  mutation CreateEbookChapter(
    $createNewEbookChapterInput: CreateNewEbookChapterInput!
  ) {
    createEbookChapter(
      CreateNewEbookChapterInput: $createNewEbookChapterInput
    ) {
      id
    }
  }
`
