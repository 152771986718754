import { gql } from 'graphql-request'

export const GET_RECOMMEND_EBOOK = gql`
  query RecommendEbook(
    $recommendType: recommendEbookType!
    $recommendLimit: Int!
  ) {
    recommendEbook(
      recommendType: $recommendType
      recommendLimit: $recommendLimit
    ) {
      id
      title
      cover
      tags {
        id
        name
      }
      category {
        id
        name
      }
      penName {
        firstPenName
        user {
          id
          profilePageSlug
        }
      }
      coverImgPath
      coverResizeImgPath
      wishlistCount
      bookType
      promotion {
        id
        discountPrice
        startPublishedAt
        endPublishedAt
        price
        isPublish
        discountCoin
        discountPercent
      }
      price
      avgRating
      reviewCount
      isWishlist
    }
  }
`
