import { gql } from 'graphql-request'

export const GET_EBOOK_READER_DETAIL = gql`
  query GetEbookReaderDetail($ebookId: Int!) {
    getEbookReaderDetail(ebookId: $ebookId) {
      book {
        id
      }
      id
      coverImgPath
      title
      writer
      cover
      bookType
      writingType
      isTranslated
      isWishlist
      wishlistCount
      viewCount
      reviewCount
      lastCommentDate
      intro
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      isPaid
      penName {
        id
        firstPenName
        facebookLink
        twitterLink
        user {
          id
          displayName
          avatarImgPath
          profilePageSlug
          writer {
            affiliation
            status
          }
          email
          isAdmin
        }
      }

      tags {
        id
        name
      }
      category {
        id
        name
      }
      publishedAt
      createdAt
      updatedAt
      dynamicLink
      facebookShareImageUrl
      twitterShareImageUrl
      requireAgeVerify
      isbnNo
      promotion {
        id
        discountPrice
        startPublishedAt
        endPublishedAt
        price
        discountCoin
        discountPercent
        isPublish
      }
      avgRating
      price
      ebookReviewStatus
      ebookChapters {
        id
        isTrial
      }
      rating {
        id
        name
      }
      totalPageCount
      totalWordCount
      chapterCount
    }
  }
`
