import { gql } from 'graphql-request'

export const SEARCH_WRITING_EBOOK_PROFILE = gql`
  query SearchWritingEbookProfile(
    $userId: Int!
    $penNameId: Int
    $bookType: [BookType!]
    $page: Int
    $limitPerPage: Int
  ) {
    searchWritingEbookProfile(
      userId: $userId
      penNameId: $penNameId
      bookType: $bookType
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        title
        bookType
        coverImgPath
        totalEbookSeries
        avgEbookRating
        totalEbookReview
        totalEbookWishlist
        totalEbookSeriesPublish
      }
      page
      total
      totalEbook
    }
  }
`
