import { Expose } from 'class-transformer'

export class PromotionEbookType {
  @Expose() id: number

  @Expose() discountPrice: number

  @Expose() startPublishedAt: string

  @Expose() endPublishedAt: string

  @Expose() price: number

  @Expose() isPublish: boolean

  @Expose() discountCoin: number

  @Expose() discountPercent: number
}
