import { gql } from 'graphql-request'

export const UPDATE_EBOOK_SETTING = gql`
  mutation UpdateEbookSetting(
    $updateEbookSettingInput: UpdateEbookSettingInput!
  ) {
    updateEbookSetting(UpdateEbookSettingInput: $updateEbookSettingInput) {
      id
    }
  }
`
