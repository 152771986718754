import { Expose } from 'class-transformer'

export class EbookChapterType {
  @Expose() bookChapterId: number | null

  @Expose() isEdited: boolean

  @Expose() isTrial: boolean

  @Expose() updatedAt: string

  @Expose() id: number

  @Expose() chapterTitle: string

  @Expose() chapterName: string

  @Expose() runningNo: number
}
