import { gql } from 'graphql-request'

export const GET_EBOOK_CHAPTER = gql`
  query GetEbookChapter($chapterId: Int!, $ebookId: Int!) {
    getEbookChapter(chapterId: $chapterId, ebookId: $ebookId) {
      chapterCoverImgPath
      chapterName
      chapterTitle
      writerMessage
      ebookChapterPages {
        content
        id
        size
        ordered
        imgPath
      }
    }
  }
`
